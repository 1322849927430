<template>
  <div>
    <PlgUsersApi ref="PlgUsersApi"></PlgUsersApi>
    <Toasts ref="Toasts"></Toasts>
    <div v-if="user">
      <div class="col-xl-12 mb-3">
        <div class="row">
          <div class="col-6">
            <h4 class="font-weight-bold mt-2">
              {{ getTitleForm }}
            </h4>
            <b-badge v-if="user.id" pill :variant="bankDataBadgeVariant">
              <span class="mt-2">
                {{ bankDataConfirmationStatus.toUpperCase() }}
              </span>
            </b-badge>
          </div>
          <div class="col-6 text-right">
            <b-btn
              v-show="!isLoading"
              class="btn btn-success btn-sm text-white mr-2"
              @click="sendUserDataApi()"
              v-b-tooltip.html.bottom
              title="Confirmar"
              ><i class="mdi mdi-check" />
            </b-btn>
            <b-btn
              v-show="isLoading"
              class="btn btn-success btn-sm text-white mr-2"
              @click="sendUserDataApi()"
              v-b-tooltip.html.bottom
              disabled
            >
              <div class="text-center align-items-center">
                <b-spinner small></b-spinner>
              </div>
            </b-btn>
            <b-btn
              class="btn btn-danger btn-sm text-white mr-2"
              @click="goToListUsers()"
              v-b-tooltip.html.bottom
              title="Cancelar"
              ><i class="mdi mdi-close" />
            </b-btn>
          </div>
        </div>
      </div>
      <form @submit.prevent="" @reset.prevent="" class="forms-sample mt-3">
        <div class="col-xl-12">
          <div class="row">
            <!--DETALHES-->
            <div class="col-xl-6 grid-margin stretch-card">
              <div class="card border-0">
                <div class="card-body">
                  <h4 class="card-title">Detalhes</h4>
                  <div class="container-fluid">
                    <b-form-group label="Nome" label-for="inputName">
                      <b-form-input
                        v-model="user.name"
                        type="text"
                        placeholder="Nome"
                        id="inputName"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.name.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.name.required"
                          >Nome é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group label="Celular" label-for="inputCellphone">
                      <b-form-input
                        v-model="user.cellphone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        type="text"
                        id="inputCellphone"
                        placeholder="Celular"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.cellphone.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.cellphone.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.cellphone.required"
                          >Celular é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Data de Nascimento"
                      label-for="inputBirthday"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="user.birthday"
                          v-mask="['##/##/####']"
                          id="inputBirthday"
                          placeholder="Data de Nascimento"
                        >
                        </b-form-input>
                        <b-form-datepicker
                          v-model="birthdayFromDatePicker"
                          button-only
                          button-variant="primary"
                          placeholder="Escolha uma data"
                          locale="pt-br"
                          size="sm"
                          label-help="Use as setas para navegar"
                          label-no-date-selected
                          :date-format-options="{
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                          }"
                          :max="maxDate"
                          selected-variant="primary"
                          hide-header
                          :initial-date="initialDate"
                        ></b-form-datepicker>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Email" label-for="inputEmail">
                      <b-form-input
                        v-model="user.email"
                        type="text"
                        placeholder="Email"
                        id="inputEmail"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.email.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.required"
                          >Email é obrigatório.</span
                        >
                      </div>
                      <div
                        v-if="submitted && $v.user.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.email.email"
                          >Email deve ser válido.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      v-if="!userId"
                      label="Senha"
                      label-for="inputPassword"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="user.password"
                          :type="seePassword ? 'text' : 'password'"
                          placeholder="Senha"
                          id="inputPassword"
                          size="sm"
                          :class="{
                            'is-invalid': submitted && $v.user.password.$error,
                          }"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="seePassword = !seePassword"
                          >
                            <i
                              :class="
                                seePassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'
                              "
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <div
                        v-if="submitted && $v.user.password.$error"
                        class="invalid-feedback d-inline"
                      >
                        <span v-if="!$v.user.password.required"
                          >Senha é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Documento (CPF/CNPJ)"
                      label-for="inputFederalId"
                    >
                      <b-input-group>
                        <b-form-input
                          id="inputFederalId"
                          v-model="user.federal_id"
                          placeholder="Documento"
                          v-mask="
                            user.is_company
                              ? '##.###.###/####-##'
                              : '###.###.###-##'
                          "
                          type="text"
                          :class="{
                            'is-invalid':
                              submitted && $v.user.federal_id.$error,
                          }"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-radio-group
                            v-model="user.is_company"
                            button-variant="outline-primary"
                            :options="documents"
                            name="radios-btn-default"
                            size="sm"
                            buttons
                          ></b-form-radio-group>
                        </b-input-group-append>
                        <div
                          v-if="submitted && $v.user.federal_id.$error"
                          class="invalid-feedback"
                        >
                          <span
                            >{{
                              user.is_company ? "CNPJ" : "CPF"
                            }}
                            inválido!</span
                          >
                        </div>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Permissões" label-for="inputRole">
                      <b-form-select
                        v-model="user.role"
                        :options="userRolesOpts"
                        id="inputRole"
                        size="md"
                        :class="{
                          'is-invalid': submitted && $v.user.role.$error,
                        }"
                      ></b-form-select>
                      <div
                        v-if="submitted && $v.user.role.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.role.required"
                          >Permissões do usuário devem ser especificadas.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!--ENDEREÇO DO USUÁRIO-->
            <div class="col-xl-6 grid-margin stretch-card">
              <div class="card border-0">
                <div class="card-body">
                  <h4 class="card-title">Endereço</h4>
                  <div class="container-fluid">
                    <b-form-group label="CEP" label-for="inputZipCode">
                      <b-form-input
                        v-model="user.zip_code"
                        type="text"
                        id="inputZipCode"
                        v-mask="['#####-###']"
                        @change="getZipCodeData"
                        placeholder="CEP"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.zip_code.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.zip_code.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.zip_code.required"
                          >CEP é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group label="Logradouro" label-for="inputAddress">
                      <b-form-input
                        v-model="user.address"
                        type="text"
                        id="inputAddress"
                        placeholder="Logradouro"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.address.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.address.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.address.required"
                          >Logradouro é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group label="Número" label-for="inputHouseNumber">
                      <b-form-input
                        v-model="user.house_number"
                        type="text"
                        id="inputHouseNumber"
                        placeholder="Número"
                        size="sm"
                        :class="{
                          'is-invalid':
                            submitted && $v.user.house_number.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.house_number.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.house_number.required"
                          >Número é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Complemento"
                      label-for="inputAddressComplement"
                    >
                      <b-form-input
                        v-model="user.address_complement"
                        type="text"
                        id="inputAddressComplement"
                        placeholder="Complemento"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Bairro" label-for="inputQuarter">
                      <b-form-input
                        v-model="user.quarter"
                        type="text"
                        id="inputQuarter"
                        placeholder="Bairro"
                        size="sm"
                        :class="{
                          'is-invalid': submitted && $v.user.quarter.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.user.quarter.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.quarter.required"
                          >Bairro é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group label="Cidade" label-for="inputCity">
                      <b-form-select
                        v-model="user.city"
                        :options="citiesOptions"
                        id="inputCity"
                        placeholder="Cidade"
                        size="md"
                        :class="{
                          'is-invalid': submitted && $v.user.city.$error,
                        }"
                      ></b-form-select>
                      <div
                        v-if="submitted && $v.user.city.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.city.required"
                          >Cidade é obrigatória.</span
                        >
                      </div>
                    </b-form-group>
                    <b-form-group label="Estado" label-for="inputState">
                      <b-form-select
                        v-model="user.state"
                        :options="states"
                        id="inputState"
                        placeholder="Estado"
                        size="md"
                        @change="getCitiesOptions"
                        :class="{
                          'is-invalid': submitted && $v.user.state.$error,
                        }"
                      ></b-form-select>
                      <div
                        v-if="submitted && $v.user.state.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.state.required"
                          >Estado é obrigatório.</span
                        >
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!--DADOS BANCÁRIOS DO USUÁRIO-->
            <div class="col-xl-12">
              <div class="card border-0">
                <div class="card-body">
                  <b-row>
                    <b-col cols="8" md="6">
                      <h4 class="card-title">Dados Bancários</h4>
                    </b-col>
                  </b-row>
                  <div class="container-fluid">
                    <b-form-group
                      label="Banco/Instituição Financeira"
                      label-for="bankCode"
                    >
                      <multiselect
                        v-model="user.bank_code"
                        :options="ispbOptions"
                        tag-placeholder="Selecione"
                        placeholder="Selecione"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel="X"
                        label="fullName"
                        track-by="ispb"
                      >
                        <template #noOptions> Faça uma busca... </template>
                        <template #noResult>
                          Sem resultados! Por favor, refaça sua busca.
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Agência" label-for="branchCode">
                      <b-form-input
                        v-model="user.branch_code"
                        type="text"
                        id="branchCode"
                        v-mask="'####'"
                        placeholder="Agência"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Dígito da Agência"
                      label-for="branchCodeDigit"
                    >
                      <b-form-input
                        v-model="user.branch_code_digit"
                        type="text"
                        id="branchCodeDigit"
                        v-mask="'#'"
                        placeholder="Dígito da Agência"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Conta" label-for="accountNumber">
                      <b-form-input
                        v-model="user.account_number"
                        type="text"
                        id="accountNumber"
                        placeholder="Conta"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Dígito da Conta"
                      label-for="accountNumberDigit"
                    >
                      <b-form-input
                        v-model="user.account_number_digit"
                        type="text"
                        id="accountNumberDigit"
                        v-mask="'#'"
                        placeholder="Dígito da Conta"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Tipo de Conta"
                      label-for="accountTypeInput"
                    >
                      <b-form-select
                        id="accountTypeInput"
                        :options="accountTypeOptions"
                        v-model="user.account_type"
                      />
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import customValidators from "../../plugins/vuelidateValidators";
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import statesOptions from "../../helpers/users-opts/statesOptions";
import roles from "../../helpers/users-opts/usersRolesOpts";
import Toasts from "../../components/Toasts.vue";
import { cities } from "brazil-geodata";
import moment from "moment";
import ispbOptions from "../../helpers/users-opts/ispbOptions";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

export default {
  name: "PlgUserForm",
  components: {
    Toasts,
    PlgUsersApi,
    Multiselect,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const initialDate = new Date(today);
    initialDate.setFullYear(initialDate.getFullYear() - 18);
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    maxDate.setDate(now.getDate());
    return {
      birthdayFromDatePicker: null,
      isLoading: false,
      states: statesOptions.statesOptions,
      userRolesOpts: roles.userRolesOpts,
      initialDate: initialDate,
      bankDataFromApi: false,
      maxDate: maxDate,
      accountTypeOptions: [
        { text: "Corrente", value: "checking" },
        { text: "Poupança", value: "savings" },
        { text: "Salário", value: "salary" },
      ],
      citiesOptions: [{ text: "Cidade", value: null }],
      ispbOptions: ispbOptions,
      seePassword: false,
      userId: null,
      documents: [
        { text: "CPF", value: false },
        { text: "CNPJ", value: true },
      ],
      user: {},
      eyeIconPass: "mdi mdi-eye mdi-18px",
      eyeIconRePass: "mdi mdi-eye mdi-18px",
      submitted: false,
    };
  },
  validations() {
    if (!this.userId) {
      return {
        user: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          cellphone: {
            required,
            minLength: minLength(11),
          },
          federal_id: {
            required,
            federalIdValidCheck: customValidators.federalIdValidCheck(),
          },
          role: {
            required,
          },
          password: {
            required,
          },
          address: {
            required,
          },
          house_number: {
            required,
          },
          quarter: {
            required,
          },
          city: {
            required,
          },
          zip_code: {
            required,
          },
          state: {
            required,
          },
        },
      };
    } else {
      return {
        user: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          cellphone: {
            required,
            minLength: minLength(11),
          },
          federal_id: {
            required,
            federalIdValidCheck: customValidators.federalIdValidCheck(),
          },
          role: {
            required,
          },
          address: {
            required,
          },
          house_number: {
            required,
          },
          quarter: {
            required,
          },
          city: {
            required,
          },
          zip_code: {
            required,
          },
          state: {
            required,
          },
        },
      };
    }
  },
  methods: {
    goToListUsers() {
      this.$router.push({ name: "users" }).catch();
    },
    formatDateFromDatePicker() {
      this.user.birthday = new Date(
        this.birthdayFromDatePicker
      ).toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      });
    },
    getUserBank() {
      this.user.bank_code = this.ispbOptions.filter((element) => {
        return element.ispb == this.user.bank_code;
      })[0];
    },
    clearUserAddress() {
      const userAddressFields = [
        "address",
        "house_number",
        "address_complement",
        "quarter",
        "city",
        "state",
        "zip_code",
      ];
      Object.keys(this.user).forEach((key) => {
        if (userAddressFields.includes(key)) {
          this.user[key] = null;
        }
      });
    },
    checkIfCityIsInCitiesOptions() {
      this.citiesOptions.indexOf(this.user.city) >= 0
        ? true
        : (this.user.city = null);
    },
    async getCitiesOptions() {
      this.citiesOptions = await cities.getCitiesByState(this.user.state);
      this.citiesOptions = this.citiesOptions.map((city) => {
        return city.text;
      });
      this.checkIfCityIsInCitiesOptions();
      this.citiesOptions.splice(0, 0, { text: "Cidade", value: null });
    },
    async getZipCodeData() {
      if (this.user.zip_code) {
        let response = await this.$viaCep.buscarCep(this.user.zip_code);
        this.clearUserAddress();
        this.user.address = response.logradouro;
        this.user.quarter = response.bairro;
        this.user.state = response.uf;
        this.getCitiesOptions();
        this.user.city = response.localidade;
        this.user.zip_code = response.cep;
        document.getElementById("inputHouseNumber").focus();
      }
    },
    async sendUserDataApi() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          user: {
            name: this.user.name,
            cellphone: this.user.cellphone,
            email: this.user.email,
            birthday: moment(this.user.birthday, "DD-MM-YYYY").toISOString(),
            role: this.user.role,
            federal_id: this.user.federal_id,
            address: this.user.address,
            house_number: this.user.house_number,
            quarter: this.user.quarter,
            address_complement: this.user.address_complement,
            zip_code: this.user.zip_code,
            is_company: this.user.is_company,
            city: this.user.city,
            state: this.user.state,
            bank_code: this.user.bank_code ? this.user.bank_code.ispb : null,
            branch_code: this.user.branch_code,
            branch_code_digit: this.user.branch_code_digit
              ? this.user.branch_code_digit
              : null,
            account_number: this.user.account_number,
            account_number_digit: this.user.account_number_digit
              ? this.user.account_number_digit
              : null,
            account_type: this.user.account_type,
            is_confirmed: true,
            country: "Brazil",
          },
        };
        if (!this.userId) {
          payload.user["password"] = this.user.password;
        }
        this.isLoading = true;
        if (!this.userId) {
          let response = await this.$refs.PlgUsersApi.addNewUserAsAdmin(
            payload
          );
          this.isLoading = false;
          if (response.data.status === "success") {
            this.$router.push({ name: "users" }).catch();
          }
          this.$refs.Toasts.showToast(
            response.data.status,
            response.data.message
          );
        } else {
          let response = await this.$refs.PlgUsersApi.updateUserApi(
            this.userId,
            payload
          );
          this.isLoading = false;
          if (response.data.status === "success") {
            this.$router.push({ name: "users" }).catch();
          }
          this.$refs.Toasts.showToast(
            response.data.status,
            response.data.message
          );
        }
      }
    },
  },
  computed: {
    bankDataBadgeVariant() {
      switch (this.user.is_bank_data_confirmed) {
        case null:
        case undefined:
          return "warning";
        case false:
          return "danger";
        case true:
          return "success";
      }
      return null;
    },
    bankDataConfirmationStatus() {
      switch (this.user.is_bank_data_confirmed) {
        case null:
        case undefined:
          return "Dados Bancários Pendentes";
        case false:
          return "Dados Bancários Inválidos";
        case true:
          return "Dados Bancários Confirmados";
      }
      return null;
    },
    hasBankData() {
      return (
        !!this.user.branch_code &&
        !!this.user.bank_code &&
        !!this.user.account_number
      );
    },
    getTitleForm() {
      return this.user != null && this.user.id != null
        ? `Usuário Nº: ${this.user.id}`
        : "Novo usuário";
    },
  },
  watch: {
    birthdayFromDatePicker() {
      this.formatDateFromDatePicker();
    },
  },
  async mounted() {
    this.userId = this.$route.params.id;
    if (this.userId) {
      let response = await this.$refs.PlgUsersApi.getUserApi(this.userId);
      let formattedBirthday = moment(response.birthday).format("DD/MM/YYYY");
      this.user = response;
      this.user.birthday = formattedBirthday;
      this.getCitiesOptions();
      if (this.user.bank_code) this.getUserBank();
      if (this.hasBankData) {
        this.bankDataFromApi = true;
      }
    } else {
      this.user = {};
      this.user.id = null;
      this.user.is_company = false;
    }
    if (!this.$can("view", "apis")) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>
